<template>
    <div class="cover" @click='close'>
        <img class='cover_img' src="../assets/img/toAddress.png" />
        <p class='cover_tet'>请在第三方浏览器中打开此页面 <br/> 继续支付</p>
    </div>
</template>
<style scoped>
    .cover{
        position:absolute;
        top:0;
        z-index:1000;
        width:100%;
        height:100%;
        background-color:#444;
        opacity:0.3;
    }
    .cover_img{
        width: 30%;
        height: 2rem;
        position: fixed;
        top: 0.1rem;
        right: .2rem;
    }
    .cover_tet{
        text-align: center;
        color: #fff;
        width: 80%;
        margin-left: 10%;
        margin-top: 2.3rem;
        font-size: .2rem;
    }
</style>
<script>
    export default{
        data(){
            return {

            }
        },
        methods:{
            close(){
                this.$emit('close')
            }
        }
    }     
</script>